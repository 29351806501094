<template>
  <div class="wrapper">
    <div class="title">轮播图管理</div>
    <div class="main">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="大数据小程序" name="1"></el-tab-pane>
        <el-tab-pane label="评估师小程序" name="2"></el-tab-pane>
      </el-tabs>
      <!-- 内容部分 -->
      <div class="wrapper_flex">
        <!-- 快估轮播图 -->
        <div class="flex_image">
          <template v-if="activeName == 1">
            <img class="image1"
                 src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/staff/quick_valuation_2.png"
                 alt=""/>
            <img class="image2"
                 src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/staff/quick_valuation_1.png"
                 alt=""/>
          </template>
          <template v-if="activeName == 2">
            <img class="image3"
                 src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/staff/detail_valuation.png" alt=""/>
          </template>
          <div class="swiper_view" :class="activeName == 2 ? 'h122' : ''">
            <div ref="mySwiper" class="swiper-container" :class="activeName == 1 ? 'w170' : 'w122'">
              <div class="swiper-wrapper">
                <div v-for="(item, index) in list" :key="index" class="swiper-slide swiper-slide1">
                  <img style="width:100%;height: 100%;" :src="item.imageUrl" alt=""/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex_cont">
          <div class="flex_right">
            <div class="flex_view">
              <div class="title">轮播图设置</div>
              <div class="text" v-if="activeName == 1">
                建议尺寸：750*450px，最多添加10张
              </div>
              <div class="text" v-if="activeName == 2">
                建议尺寸：750*300px，最多添加10张
              </div>
              <div class="text">小程序跳转说明：请在图片名称上设置为下方对应名称；</div>
              <div class="text">如：商用车大数据平台、商用车评估师系统、商用车会务系统、商用车公共交易平台、商用车界、高安指数、基地首页地址、车架号验证。</div>
            </div>
            <div @click="onClickRight" class="button">保存</div>
          </div>
          <draggable class="bann_ul" handle=".mover" v-model="list">
            <div class="bann_li" v-for="(item, index) in list" :key="index">


              <div class="mover">
                <img class="dragImages" src="../../assets/drag.png" alt=""/>
              </div>
              <!--              :disabled="item.imageUrl ? true : false"-->
              <el-upload
                :ref="'upload'+index"
                class="avatar-uploader"
                action="https://apisgu.chinaucv.com/staffPlatform/upload/uploadImage"
                :show-file-list="false"
                :on-success="value => handleAvatarSuccess(index, value)"
                :headers="uploadHeaders"
                accept="image/jpg,image/jpeg,image/png"
                name="image"
                :data="{ type: 0, vehicleModelId: 0 }"
                :multiple="false"
                :before-upload="beforeAvatarUpload"
              >
                <div class="wrapper_replace el-upload__tip" v-if="item.imageUrl">替换</div>

                <el-image class="avatar" @click.stop.prevent="onClickReplace(index)" :zIndex="4" v-if="item.imageUrl"
                          :src="item.imageUrl"
                          :preview-src-list="[item.imageUrl]"></el-image>

                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <div class="avatar_delete" @click.stop.prevent="onClickDelete(index)">
                  <img style="width:14px;height: 14px;" src="../../assets/error.png" alt=""/>
                </div>
              </el-upload>
              <!-- <img src="" alt=""> -->
              <div class="li_centent">
                <div class="li_flex">
                  <div class="title">图片名称：</div>
                  <el-input
                    v-model="item.name"
                    placeholder="请输入图片名称"
                    type="text"
                  ></el-input>
                </div>
                <div class="li_flex">
                  <div class="title">链接地址：</div>
                  <el-input
                    v-model="item.link"
                    placeholder="请输入链接地址"
                    type="text"
                  ></el-input>
                </div>
                <div class="li_flex">
                  <div class="title">链接属性：</div>
                  <div style="flex:1;font-size: 12px;">
                    <el-radio v-model="item.isExternal" label="0"
                    >内部链接
                    </el-radio
                    >
                    <el-radio v-model="item.isExternal" label="1"
                    >外部链接/其他地址
                    </el-radio
                    >
                  </div>
                </div>
              </div>
            </div>
          </draggable>


          <!--          <el-upload-->
          <!--            :ref="'upload999'"-->
          <!--            class="avatar-uploader"-->
          <!--            :disabled="item.imageUrl ? true : false"-->
          <!--            action="https://apisgu.chinaucv.com/staffPlatform/upload/uploadImage"-->
          <!--            :show-file-list="false"-->
          <!--            :on-success="value => handleAvatarSuccess(index, value)"-->
          <!--            :headers="uploadHeaders"-->
          <!--            accept="image/jpg,image/jpeg,image/png"-->
          <!--            name="image"-->
          <!--            :data="{ type: 0, vehicleModelId: 0 }"-->
          <!--            :multiple="false"-->
          <!--            :before-upload="beforeAvatarUpload"-->
          <!--          >-->
          <!--            <div class="wrapper_replace el-upload__tip" @click="onClickReplace(index)" v-if="item.imageUrl">替换-->
          <!--            </div>-->
          <!--            <el-image class="avatar" :zIndex="4" v-if="item.imageUrl" :src="item.imageUrl"-->
          <!--                      :preview-src-list="[item.imageUrl]"></el-image>-->
          <!--            <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
          <!--            <div class="avatar_delete" @click.stop="onClickDelete(index)">-->
          <!--              <img style="width:14px;height: 14px;" src="../../assets/error.png" alt=""/>-->
          <!--            </div>-->
          <!--          </el-upload>-->
          <div @click="onclickAddImages" class="button">添加照片</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Swiper from 'swiper' // 注意引入的是Swiper
import 'swiper/css/swiper.min.css' // 注意这里的引入

export default {
  components: {
    draggable
  },
  data () {
    return {
      newSwiper: '', //实例
      list: [], //数据
      uploadHeaders: {},
      activeName: '1',
      input: ''
    }
  },
  created () {
    // 获取token
    let token = sessionStorage.getItem('token')
    this.uploadHeaders = { token }
  },
  mounted () {
    this.initData(true)
  },
  methods: {
    initSwiper () {
      this.newSwiper = new Swiper(this.$refs.mySwiper, {
        direction: 'horizontal' /*横向滑动*/,
        autoplay: {
          disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay （没有加入之前轮播图设置 autoplay：true无效）
          delay: 3000 // 自动切换的时间间隔（单位ms）
        },
        observer: true,
        observeParents: true
      })
    },
    handleClick () {
      this.initData(false)
      // this.list = [];
      // this.$nextTick(() => {
      //     this.initSwiper();
      //     this.newSwiper.update();
      // });
    },
    //图片
    handleAvatarSuccess (index, res) {
      console.log(res.content.urls)
      this.list[index].imageUrl = res.content.urls[0]
      this.$nextTick(() => {
        this.newSwiper.update()
      })
    },
    // 上传前检验
    beforeAvatarUpload (file) {
      // 开始加载
    },
    // 添加照片
    onclickAddImages () {
      if (this.list.length >= 10) {
        return this.$message({
          message: '最多添加10张',
          type: 'warning'
        })
      }
      let platform
      // 1 大数据  2 评估师
      if (this.activeName == 1) {
        platform = '1'
      } else {
        platform = '2'
      }
      this.list.push({
        platform, //平台
        name: '', //图片名称
        imageUrl: '', //图片地址
        link: '', //链接
        isExternal: '0', //是否外部链接
        sortId: '' //排序ID
      })
      this.$nextTick(() => {
        this.newSwiper.update()
      })
    },
    // 删除图片
    onClickDelete (index) {
      this.list.splice(index, 1)
      this.$nextTick(() => {
        this.newSwiper.update()
      })
    },
    onClickReplace (index) {
      return
    },
    // 提交接口
    async onClickRight () {
      let list = JSON.parse(JSON.stringify(this.list))
      if (list.length == 0) {
        return this.$message({
          message: '请添加图片',
          type: 'warning'
        })
      }

      for (var i = 0; i < list.length; i++) {
        if (this.list[i].imageUrl == '') {
          return this.$message({
            message: '请上传图片',
            type: 'warning'
          })
        }
        if (this.list[i].name == '') {
          return this.$message({
            message: '请填写图片名称',
            type: 'warning'
          })
        }

        list[i].sortId = i
        list[i].platform = list[i].platform - 0
        list[i].isExternal = list[i].isExternal - 0
      }
      console.log(this.list)
      const { data: res } = await this.$http({
        url: '/booth/saveBanners',
        method: 'POST',
        data: { banners: list }
      })
      if (res.resultStates != 0) {
        return this.$message({
          message: res.message,
          type: 'warning'
        })
      }
      // this.$message
      this.$message({
        message: '提交成功',
        type: 'success'
      })
      this.initData(false)
    },
    async initData (type) {
      let platform = this.activeName
      const { data: res } = await this.$http({
        url: '/booth/getBanners',
        method: 'POST',
        data: { platform }
      })
      res.content.forEach(item => {
        console.log(item)
        if (!item.isExternal) {
          item.isExternal = '0'
        } else {
          item.isExternal = '1'
        }
      })
      this.list = res.content
      setTimeout(() => {
        if (type) {
          this.initSwiper()
        }
        this.newSwiper.update()
      }, 100)
    }
  }
}
</script>
<style scoped lang="less">
.wrapper > .title {
  font-size: 18px;
  font-weight: bold;
  box-sizing: border-box;
  padding-left: 50px;
  margin-bottom: 14px;
}

.wrapper>.main {
    width: 100%;
    height: 680px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 30px rgba(0, 131, 227, 0.2);
    opacity: 1;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px 0;
}

/deep/ .el-tabs__item {
  padding-left: 50px !important;
}

.wrapper_flex {
    width: 100%;
    height: 600px;
    margin-top: 10px;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0 66px 20px 50px;
}

.flex_image {
  position: relative;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  height: 100%;
  float: left;
  background: #f9f9f9;
}

.flex_image > .image3 {
  z-index: 3;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.flex_image > .image1 {
  z-index: 3;
  width: 100%;
  height: 52px;
  position: absolute;
  top: 0;
}

.flex_image > .image2 {
  z-index: 3;
  width: 100%;
  height: 340px;
  position: absolute;
  bottom: 0;
}

.flex_cont {
  width: calc(100% - 300px);
  height: 100%;
  float: right;
  box-sizing: border-box;
  padding-left: 40px;
}

.flex_cont > .button {
  width: 132px;
  height: 36px;
  background: rgba(0, 131, 227, 1);
  border-radius: 6px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  margin-top: 30px;
}

.flex_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex_view {
  flex: 1;
}

.flex_view > .title {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #666666;
}

.flex_view > .text {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  margin-top: 10px;
}

.flex_right > .button {
  width: 132px;
  height: 36px;
  background: rgba(0, 131, 227, 1);
  border-radius: 6px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
}

.bann_ul {
    width: 100%;
    height: 380px;
    margin-top: 30px;
    overflow: scroll;
}

.bann_li {
  width: 100%;
  height: 132px;
  border-radius: 6px;
  border: 1px dashed #d8d8d8;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.avatar-uploader {
  width: 90px;
  min-width: 90px;
  max-width: 90px;
  height: 90px;
  background: rgba(229, 229, 229);
  position: relative;
}

.avatar {
  width: 90px;
  min-width: 90px;
  max-width: 90px;
  height: 90px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
}

.li_centent {
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.li_flex {
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.li_flex > .title {
  width: 70px;
  min-width: 70px;
  max-width: 70px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}

.li_flex > .el-input {
  flex: 1;
  height: 24px;
  box-sizing: border-box;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}

/deep/ .li_flex .el-input__inner {
  height: 24px;
}

.avatar_delete {
  position: absolute;
  top: -2px;
  right: 0;
}

.swiper_view {
  width: 100%;
  height: 170px;
  position: absolute;
  top: 52px;
  z-index: 2;
}

.small {
  background: pink;
  width: 100%;
  height: 100%;
}

/deep/ .el-radio__label {
  font-size: 12px;
}

/deep/ .el-carousel__indicators--horizontal {
  opacity: 0;
}

.w170 {
  height: 170px !important;
}

.w122 {
  height: 122px !important;
}

.h122 {
  z-index: 4;
  height: 122px;
}

.dragImages {
  width: 12px;
  height: 34px;
  padding-right: 20px;
  cursor: pointer;
}

.wrapper_replace {
  width: 100%;
  height: 20px;
  line-height: 20px;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  font-size: 12px;
  color: #fff;
}
</style>
